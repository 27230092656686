import React from 'react'
import styles from './Footer.module.scss'

interface IFooterProps {
    color?: string
}

const Footer: React.FC<IFooterProps> = ({ color }) => {
    return (
        <div className={`${styles.Footer} ${color === "white" ? styles.white : ""}`}>
            <div className={styles.top}>
                <div className={styles.info}>
                    <a href="tel:+79299023710" className={`${styles.phone} ${styles.bold}`}>+7 (929) 902 37-10</a>
                    <a href="mailto:cozy.aparts@yandex.ru?subject=&body=" className={`${styles.mail} ${styles.bold}`}>cozy.aparts@yandex.ru</a>
                    <a href="https://t.me/cozzyaparts" target="_blank" className={`${styles.tg} ${styles.bold}`}>@cozyaparts</a>
                </div>
                <div className={styles.more}>
                    <span className={`${styles.bold}`}>© Cozy Aparts</span>
                    <span className={`${styles.bold}`}>1-ый Красногвардейский проезд 21с2</span>
                    <span className={`${styles.bold}`}>Москва</span>
                </div>
            </div>
            <div className={styles.docs}>
                <span className={`${styles.thin}`}>Правила оказания услуг</span>
                <span className={`${styles.thin}`}>Политика обработки персональных данных</span>
            </div>
        </div>
    )
}

export default Footer