import React, { useEffect, useState } from 'react'
import styles from './WonderfulAparts.module.scss'
import WonderfulHeader from '../../Components/WonderfulHeader/WonderfulHeader';
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, storage } from '../../fireebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import Gallery from '../../Components/Gallery/Gallery';

interface IApartRef {
    img: string,
    tower: string,
    price: string,
    area: string,
    floor: string,
    Name: string,
    descripton: string,
}
interface IApart {
    mainImg: string,
    littleImgs: string[],
    tower: string,
    price: string,
    area: string,
    floor: string,
    Name: string,
    descripton: string,
}

const skeletonAparts =
    [{
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: ""
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: ""
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: ""
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: ""
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: ""
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: ""
    } as IApart
    ] as IApart[]

const WonderfulAparts = () => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const [device, setDevice] = useState(window.innerWidth > 850 ? "PC" : window.innerWidth > 780 ? "Tablet" : "Mobile")
    const [isPending, setIsPending] = useState(true);
    const [aparts, setAparts] = useState<IApart[]>(skeletonAparts);
    const [apartsRef, setApartsRef] = useState<IApartRef[]>();
    const [galleryImages, setGalleryImages] = useState<string[]>();
    const [isOpenGallery, setIsOpenGallery] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const removeSkeleton = (element: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const parentElement = (element.target as HTMLElement).parentElement;

        if (parentElement) {
            // Находим дочерний элемент по классу
            const childToRemove = parentElement.querySelector('span[aria-live="polite"]');
            if (childToRemove) {
                // Удаляем дочерний элемент
                parentElement.removeChild(childToRemove);
            }
        }
    }
    const handleGallery = (i?: number) => {
        if (!isOpenGallery && i !== undefined) {
            setCurrentImage(i);
        }
        setTimeout(() => {
            setIsOpenGallery(!isOpenGallery);
        }, 0)
    }
    const fetchGallery = async (name: string) => {
        try {
            // Ссылка на папку с изображениями
            const folderRef = ref(storage, "Gallery");

            // Получаем список всех файлов в папке
            const result = await listAll(folderRef);

            // Получаем URL для каждого изображения
            const urlPromises = result.items.map(item => getDownloadURL(item));
            const urls = await Promise.all(urlPromises);

            if (urls) {
                setGalleryImages(urls)
            }
        } catch (error) {
            console.error('Ошибка при получении ссылок на фотографии:', error);
            return [];
        }
    }

    useEffect(() => {
        const tempAparts: IApart[] = [];
        const fetchAparts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "aparts"));
                const apartsData: IApartRef[] = querySnapshot.docs.map((doc) => ({
                    ...doc.data()
                })) as IApartRef[];
                setApartsRef(apartsData);
                for (const myApartRef of apartsData) {
                    const shortImgRef = ref(storage, myApartRef.img + "/Short");

                    // Получаем список всех файлов в папке
                    const shortImgs = await listAll(shortImgRef);

                    // Получаем URL для каждого изображения
                    const urlPromises = shortImgs.items.map(item => getDownloadURL(item));
                    const urls = await Promise.all(urlPromises);

                    if (urls) {
                        const newApart = {
                            tower: myApartRef.tower,
                            price: myApartRef.price,
                            area: myApartRef.area,
                            floor: myApartRef.floor,
                            Name: myApartRef.Name,
                            descripton: myApartRef.descripton,
                            mainImg: urls[0],
                            littleImgs: [urls[1], urls[2], urls[3]],
                        } as IApart
                        tempAparts.push(newApart);
                    }
                }
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            } finally {
                // setCurrentScroll(Math.floor(tempAparts.length))
                setIsPending(false)
                setAparts(tempAparts);
            }
        };

        fetchAparts();

        const updateDevice = () => {
            if (window.innerWidth > 850) {
                setDevice("PC")
            }
            else if (window.innerWidth > 780) {
                setDevice("Tablet")
            }
            else {
                setDevice("Mobile")
            }
            // setDevice("Mobile")
        }
        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () => {
            window.removeEventListener('resize', updateDevice);
        };
    }, [])
    useEffect(() => {
        console.log(aparts);
    }, [aparts])
    const plusScroll = () => {
        if (aparts)
            if (currentScroll < aparts.length - 1) {
                setCurrentScroll(currentScroll + 1);
            }
    }
    const minusScroll = () => {
        if (currentScroll > 0) {
            setCurrentScroll(currentScroll - 1);
        }
    }
    const setScroll = (newScroll: number) => {
        setCurrentScroll(newScroll);
    }
    return (
        <div className={styles.wonderfulAparts}>
            <div className={styles.apartsWrapper} style={{
                // minHeight: `${window.innerHeight - 100}px`
            }}>
                {/* <div className={styles.TitleText}>
                    <span className={styles.text}>Наша колекция апартаментов</span>
                </div> */}
                <div className={styles.sliderImages}
                    style={{
                        width: `${100 * aparts.length}%`,
                        transform: `translateX(calc(${50 - currentScroll * 100 / aparts.length}% - ${device === "Mobile" ? "175" : "335.5"}px))`
                    }}>
                    {
                        aparts.map((apart, i) =>
                            <div key={apart.Name + i} className={`${styles.apart} ${i} ${currentScroll} ${i === currentScroll ? styles.current : styles.hidden}`} style={{
                                width: `calc(100% / ${aparts.length})`,
                                transform: `scale(calc(1 - ${Math.abs(currentScroll - i) < 4 ? Math.abs(currentScroll - i) * 0.25 : "1"})) translateX(${device !== "Mobile" ?
                                    // ((i - currentScroll) === -4 ? + 83 * 60 + "px" :
                                    // (i - currentScroll) === -3 ? + 83 * 78 + "px" :
                                    (i - currentScroll) === -2 ? + 83 * 23 + "px" :
                                        ((i - currentScroll) === -1 ? + 83 * 5.5 + "px" :
                                            (i - currentScroll) === 1 ? "-" + 83 * 5 + "px" :
                                                (i - currentScroll) === 2 ? "-" + 83 * 17 + "px" :
                                                    // (i - currentScroll) === 3 ? "-" + 83 * 55 + "px" :
                                                    // (i - currentScroll) === 4 ? "-" + 83 * 60 + "px" :
                                                    "0px") :
                                    Math.abs(i - currentScroll) === 1 ? (currentScroll - i) * 40 * 5 + "px" :
                                        Math.abs(i - currentScroll) === 2 ? (currentScroll - i) * 40 * 5 + "px" : "0px"
                                    }
                            )`,
                                zIndex: 20 - Math.abs(currentScroll - i),
                                transformOrigin: device === "Mobile" ? "center" : "left",
                                pointerEvents: i !== currentScroll ? "none" : "all",
                                visibility: Math.abs(currentScroll - i) < 3 ? "visible" : "hidden",
                            }}>

                                {
                                    !isPending ?
                                        <span key={apart.Name + i} className={styles.Name}>{apart.Name}</span>
                                        :
                                        <span key={apart.Name + i} className={styles.Name}>
                                            <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={device !== "Mobile" ? '53px' : '26px'} width={'250px'} />
                                        </span>
                                }
                                <div className={styles.littleImages}>
                                    {
                                        apart.littleImgs.map((littleImage, k) =>
                                            <div style={{
                                                display: Math.abs(currentScroll - i) < 2 ? "block" : "none"
                                            }} className={styles.littleImageWrapper}>
                                                <img onClick={() => handleGallery(k + 1)} onLoad={(e) => removeSkeleton(e)} src={littleImage}></img>
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'100%'} width={'100%'} />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={styles.bigImageWrapper} style={{
                                    display: (device === "PC" && Math.abs(currentScroll - i) < 3 || device !== "PC" && Math.abs(currentScroll - i) < 2) ? "block" : "none",
                                }} >
                                    <img onClick={() => handleGallery(0)} onLoad={(e) => removeSkeleton(e)} style={{
                                        opacity: `calc(1 - ${Math.abs(currentScroll - i) / 2.5})`,
                                    }} src={apart.mainImg}></img>
                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'100%'} width={'100%'} />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{
                    opacity: currentScroll < 1 ? 0 : 1,
                    pointerEvents: currentScroll < 1 ? "none" : "all",
                }} onClick={minusScroll} className={`${styles.slideButton} ${styles.left}`}></div>
                <div style={{
                    opacity: currentScroll >= aparts.length - 1 ? 0 : 1,
                    pointerEvents: currentScroll >= aparts.length - 1 ? "none" : "all",
                }} onClick={plusScroll} className={`${styles.slideButton} ${styles.right}`}></div>
                <div className={styles.sliderInfo} style={{
                    width: `${100 * aparts.length}%`,
                    transform: `translateX(calc(${50 - currentScroll * 100 / aparts.length}% - ${device === "Mobile" ? "175" : "335.5"}px))`
                }}>
                    {
                        aparts.map((apart, i) =>
                            <div key={apart.Name + i} className={`${i} ${currentScroll} ${Math.abs(currentScroll - i)} ${styles.apartInfo}  ${i === currentScroll ? styles.showInfo : styles.hideInfo}`} style={{
                                width: `calc(100% / ${aparts.length})`,
                                opacity: `calc(1 - ${Math.abs(currentScroll - i) / 2.5})`,
                                transform: `scale(calc(1 - ${Math.abs(currentScroll - i) < 4 ? Math.abs(currentScroll - i) * 0.25 : "0"}))  translateX(${device !== "Mobile" ?
                                    (Math.abs(i - currentScroll) === 1 ? (currentScroll - i) * 20 * 19 + "px" :
                                        Math.abs(i - currentScroll) === 2 ? (currentScroll - i) * 20 * 42 + "px" :
                                            // Math.abs(i - currentScroll) === 3 ? (currentScroll - i) * 20 * 97 + "px" :
                                            //     Math.abs(i - currentScroll) === 4 ? (currentScroll - i) * 20 * 5 + "px" : 
                                            "0px") :
                                    (
                                        (Math.abs(i - currentScroll) === 1 ? (currentScroll - i) * 20 * 10 + "px" :
                                            Math.abs(i - currentScroll) === 2 ? (currentScroll - i) * 20 * 20 + "px" : "0px")
                                    )}
                            )`,
                                zIndex: 20 - Math.abs(currentScroll - i),
                                visibility: Math.abs(currentScroll - i) < 2 ? "visible" : "hidden",
                            }} onClick={() => setScroll(i)}>
                                <a href={`/apartments/${apart.Name}`} className={`${styles.infoBlock}`}>
                                    <div className={styles.infoBlockInner}>
                                        {!isPending ?
                                            <>
                                                <span className={styles.info}>Башня: {apart.tower}</span>
                                                <div className={styles.line}></div>
                                                <span className={styles.info}>Этаж: {apart.floor}</span>
                                                <div className={styles.line}></div>
                                                <span className={styles.info}>Площадь: {apart.area}</span>
                                                <div className={styles.line}></div>
                                                <span className={styles.info}>Цена: от<b> {apart.price} </b></span>
                                                <span className={styles.more}>{device === "Mobile" ? "Перейти" : "Забронировать"}</span>
                                            </> :
                                            <>
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                <div className={styles.line}></div>
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                <div className={styles.line}></div>
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                <div className={styles.line}></div>
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                <span className={styles.more}>
                                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                </span>
                                            </>
                                        }
                                    </div>
                                </a>
                                {

                                    <div className={styles.nameBlock}>
                                        {
                                            !isPending ?
                                                <span className={styles.name}>{apart.Name}</span>
                                                :
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                        }
                                    </div>
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            {apartsRef && isOpenGallery &&
                <Gallery device={device} handleGallery={handleGallery} imgRef={apartsRef[currentScroll].img} currentImages={currentImage} />}
        </div>
    )
}

export default WonderfulAparts