import React, { useEffect, useRef, useState } from 'react'
import styles from './WonderfulPageApart.module.scss'
import { useParams } from 'react-router-dom';
import { IApart } from '../../types/Models/types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, storage } from '../../fireebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import WonderfulHeader from '../../Components/WonderfulHeader/WonderfulHeader';
import Skeleton from 'react-loading-skeleton';
import { url } from 'inspector';
import Gallery from '../../Components/Gallery/Gallery';
import axios from 'axios';
import BookMenu from '../../Components/BookMenu/BookMenu';
import Footer from '../../Components/Footer/Footer';

const skeletonApart =
    {
        img: [" ", " ", " ", " ", " ", " ", " "],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        description: "",
        rooms: "",
        view: "",
        sleep: "",
    } as IApart

const WonderfulPageApart = () => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const [isPending, setIsPending] = useState(true);
    const apartName = useParams().nameApart;
    const [apart, setApart] = useState<IApart>(skeletonApart);
    const [height, setHeight] = useState(window.innerHeight);
    const [imageRef, setImageRef] = useState();
    const [isOpenGallery, setIsOpenGallery] = useState(false);
    const [device, setDevice] = useState(window.innerWidth > 850 ? "PC" : window.innerWidth > 780 ? "Tablet" : "Mobile");
    const [pageScroll, setPageScroll] = useState(0);
    const [isOpenBook, setIsOpenBook] = useState(false);
    const [isShowBook, setIsShowBook] = useState(false);
    const bookRef = useRef<HTMLButtonElement>(null);
    const plusScroll = () => {
        console.log("plus")
        if (currentScroll < apart.img.length - 1) {
            setCurrentScroll(currentScroll + 1);
        }
    }
    const minusScroll = () => {
        if (currentScroll > 0) {
            setCurrentScroll(currentScroll - 1);
        }
    }
    const slide = () => {
        const targetChildElement = document.querySelector(`.element_${currentScroll}`) as HTMLDivElement;
        var slideElement;
        if (targetChildElement) {
            slideElement = targetChildElement.closest('.my-slider') as HTMLDivElement;
            // slideElement && targetChildElement.scrollIntoView({ behavior: 'smooth' });  // Ручной вариант
            slideElement.scrollTo({
                left: currentScroll * 83 - (308.5),
                behavior: 'smooth'  // Добавим плавную прокрутку
            });
        }
    }

    const handleGallery = (i?: number) => {
        setTimeout(() => {
            setIsOpenGallery(!isOpenGallery);
        }, 0)
    }

    useEffect(() => {
        const fetchAparts = async () => {
            try {
                const collectionRef = collection(db, 'aparts'); // Замените 'Aparts' на имя вашей коллекции

                // Создаем запрос с фильтрацией по значению поля
                const q = query(collectionRef, where("Name", "==", apartName));

                // Выполняем запрос
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                } else {
                    querySnapshot.forEach(async (doc) => {
                        try {
                            const apartData = doc.data();
                            const folderRef = ref(storage, apartData.img + "/All");
                            setImageRef(apartData.img)
                            const result = await listAll(folderRef);

                            const urlPromises = result.items.map(item => getDownloadURL(item));
                            const urls = await Promise.all(urlPromises);
                            if (urls) {
                                setApart({
                                    tower: apartData.tower,
                                    price: apartData.price,
                                    area: apartData.area,
                                    floor: apartData.floor,
                                    Name: apartData.Name,
                                    description: apartData.description,
                                    img: urls,
                                    view: apartData.view,
                                    sleep: apartData.sleep,
                                    rooms: apartData.rooms,
                                } as IApart)
                            }
                            setIsPending(false);
                        } catch (error) {
                            console.error('Ошибка при получении ссылок на фотографии:', error);
                            return [];
                        }
                    });
                }
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
            const updateHeight = () => {
                setHeight(window.innerHeight);
                if (window.innerWidth > 850) {
                    setDevice("PC")
                }
                else if (window.innerWidth > 780) {
                    setDevice("Tablet")
                }
                else {
                    setDevice("Mobile")
                }
                // setDevice("Mobile")
            }
            const updateScroll = () => {
                setPageScroll(window.scrollY);
            }
            window.addEventListener("resize", updateHeight);
            window.addEventListener("scroll", updateScroll);
            return () => {
                window.removeEventListener('resize', updateHeight);
                window.removeEventListener("scroll", updateScroll);
            };
        };

        fetchAparts();

    }, [])

    useEffect(() => {
        if (bookRef.current && bookRef.current?.getBoundingClientRect().top + bookRef.current?.getBoundingClientRect().height < 0) {
            setIsShowBook(true);
        }
        else {
            setIsShowBook(false);
        }
    }, [pageScroll])

    useEffect(() => {
        if (isOpenBook) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpenBook])
    const removeSkeleton = (element: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const parentElement = (element.target as HTMLElement).parentElement;
        if (parentElement) {
            // Находим дочерний элемент по классу
            const childToRemove = parentElement.querySelector('span[aria-live="polite"]');
            const childImg = parentElement.querySelector('img') as HTMLImageElement;
            if (childToRemove && childImg) {
                childImg.style.display = "block";
                // Удаляем дочерний элемент
                parentElement.removeChild(childToRemove);
            }
        }
    }

    return (
        <div className={styles.PageApart}>
            <button onClick={() => setIsOpenBook(true)} className={`${styles.mobileBook} ${styles.top} ${isShowBook ? styles.visible : ""}`} style={{
                display: device === "Mobile" || device === "Tablet" ? 'flex' : "none",
                alignItems: "center",
                justifyContent: "center",
                gap: 5
            }}>
                Забронировать от
                {apart.price ?
                    <span style={{
                        fontWeight: "bold",
                    }}>{apart.price}₽</span>
                    :
                    <Skeleton baseColor='#005656' borderRadius={0} inline={true} highlightColor='#009e9e' height={'18px'} width={'67px'} />
                }
            </button>
            {isOpenBook && <div className={styles.bookMobileWrapper}>
                <button onClick={() => setIsOpenBook(false)} className={styles.exit} />
                <BookMenu apart={apart} />
            </div>}
            <div className={styles.Header}>
                <a href='/' className={styles.back}>
                    {/* <img src='/images/long_arrow.svg'></img> */}
                    <img src='/images/long_arrow_black.svg'></img>
                    <span>На главную</span>
                </a>
                <a href='/' className={styles.logo}>
                    {/* <img src='/images/new_logo.svg'></img> */}
                    <img src='/images/new_logo_black.svg'></img>
                </a>
                <a href="tel:+79299023710" className={styles.numberHeader}>+7 (929) 902 37 10</a>
            </div>
            <div className={styles.pageApartContainer}>
                <div className={styles.page}>
                    <span className={styles.titleBlock} style={{
                        display: "block",
                    }}>
                        {!isPending ?
                            `${apart.Name}`
                            :
                            <Skeleton baseColor='#b7b7b7' borderRadius={0} inline={false} highlightColor='#ececec' height={device === "Mobile" || device === "Tablet" ? '18px' : "40px"} width={device === "Mobile" || device === "Tablet" ? '100px' : "200px"} />
                        }
                    </span>
                    {/* <WonderfulHeader position='Fixed' isApart={true} hide={pageScroll < height ? true : false} apart={apart} /> */}
                    <div className={styles.pageContainer}>
                        <div className={styles.imageBlock}>
                            <div className={styles.bigImage}>
                                <div className={`${styles.imagesSlider}`} style={{
                                    width: `${100 * apart?.img.length}%`,
                                    transform: `translateX(${-currentScroll * 100 / apart.img.length}%)`
                                }}
                                >
                                    {
                                        apart.img.map((image, i) =>
                                            <div className={styles.imageBlock} style={{
                                                width: `${100 / apart.img.length}%`,
                                            }}>
                                                <div key={image + i} className={styles.imageWrapper}>
                                                    <img onClick={() => handleGallery()} onLoad={(e) => removeSkeleton(e)} src={image}></img>
                                                    <Skeleton baseColor='#b7b7b7' borderRadius={0} inline={false} highlightColor='#ececec' height={'100%'} width={'100%'} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div style={{
                                    opacity: currentScroll < 1 ? 0 : 1,
                                    pointerEvents: currentScroll < 1 ? "none" : "all",
                                }} onClick={(e) => { minusScroll() }} className={`${styles.slideButtonGallery} ${styles.leftGallery}`}></div>
                                <div style={{
                                    opacity: currentScroll >= apart.img.length - 1 ? 0 : 1,
                                    pointerEvents: currentScroll >= apart.img.length - 1 ? "none" : "all",
                                }} onClick={(e) => { plusScroll() }} className={`${styles.slideButtonGallery} ${styles.rightGallery}`}></div>
                            </div>
                        </div>
                        <div className={styles.infoBlock}>
                            <BookMenu apart={apart} />
                        </div>
                        {/* {!isPending ?
                            <div className={styles.infoBlock}>
                                <div className={styles.aboutBlock}>
                                    <span className={styles.name}>{apart.Name.replace(/_/g, ' ')}</span>
                                    <div className={styles.info}>
                                        <span className={styles.infoText}><span className={styles.hide}>Этаж:</span> {apart.floor}</span>
                                        <span className={styles.infoText}><span className={styles.hide}>Площадь:</span> {apart.area}</span>
                                        <span className={styles.infoText}><span className={styles.hide}>Количество комнат:</span> 2</span>
                                        <span className={styles.infoText}><span className={styles.hide}>Спальных мест:</span> 6</span>
                                        <span className={styles.infoText}><span className={styles.hide}>Башня:</span> {apart.tower}</span>
                                    </div>
                                </div>
                                <div className={styles.buttonPrice}>
                                    <span className={styles.price}>от {apart.price} ₽</span>
                                    <div className={styles.button}>
                                        Забронировать
                                    </div>
                                </div>
                            </div>
                            : <div className={`${styles.infoBlock} ${styles.skeletonInfo}`}>
                                <div className={styles.aboutBlock}>
                                    <span className={styles.name}>
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'40px'} width={'200px'} /></span>
                                    <div className={styles.info}>
                                        <span className={styles.infoText}><span className={styles.hide}>Этаж:</span>
                                            <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'100%'} width={'20px'} /></span>
                                        <span className={styles.infoText}><span className={styles.hide}>Площадь:</span>
                                            <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'100%'} width={'30px'} /></span>
                                        <span className={styles.infoText}><span className={styles.hide}>Количество комнат:</span>
                                            <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'100%'} width={'20px'} /></span>
                                        <span className={styles.infoText}><span className={styles.hide}>Спальных мест:</span>
                                            <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'100%'} width={'20px'} /></span>
                                        <span className={styles.infoText}><span className={styles.hide}>Башня:</span>
                                            <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'100%'} width={'50px'} /></span>
                                    </div>
                                    <div className={styles.description}>
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'240px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'250px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'260px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'270px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'260px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'250px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'260px'} />
                                        <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'16px'} width={'270px'} />
                                    </div>
                                </div>
                                <div className={styles.buttonPrice}>
                                    <span className={styles.price}>от <Skeleton baseColor='#9b9b9b' borderRadius={0} inline={true} highlightColor='#E8EAED' height={'100%'} width={'108.02px'} /> ₽</span>
                                    <div className={styles.button}>
                                        Забронировать
                                    </div>
                                </div>
                            </div>} */}
                    </div>
                </div>
                <button ref={bookRef} onClick={() => setIsOpenBook(true)} className={styles.mobileBook} style={{
                    display: device === "Mobile" || device === "Tablet" ? 'flex' : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5
                }}>
                    Забронировать от
                    {apart.price ?
                        <span style={{
                            fontWeight: "bold",
                        }}>{apart.price}₽</span>
                        :
                        <Skeleton baseColor='#005656' borderRadius={0} inline={true} highlightColor='#009e9e' height={'18px'} width={'67px'} />
                    }
                </button>
                <div className={styles.bigAboutBlock}>
                    <span className={styles.titleBlock}>Информация об Апартаментах</span>
                    <div className={styles.content}>
                        <div className={styles.textInfo}>
                            <span className={styles.infoText}><span className={styles.hide}>Этаж:</span><span className={styles.bold}> {apart.floor}</span></span>
                            <span className={styles.infoText}><span className={styles.hide}>Площадь:</span><span className={styles.bold}> {apart.area}</span></span>
                            <span className={styles.infoText}><span className={styles.hide}>Количество комнат:</span><span className={styles.bold}> {apart.rooms}</span></span>
                            <span className={styles.infoText}><span className={styles.hide}>Спальных мест:</span><span className={styles.bold}> {apart.sleep}</span></span>
                            <span className={styles.infoText}><span className={styles.hide}>Башня:</span><span className={styles.bold}> {apart.tower}</span></span>
                            <span className={styles.infoText}><span className={styles.hide}>Вид:</span><span className={styles.bold}> {apart.view}</span></span>
                        </div>
                        <div className={styles.conditions}>
                            Цена в бронировании указана до 2-х человек, сверх этого количества доплата за гостей - 1000₽/гость/сутки
                            <br />
                            <br />
                            - Заезд с 15:00
                            <br />
                            - Выезд до 12:00
                            <br />
                            <br />
                            Поздний выезд и ранний заезд возможен по договорённости. Стоимость - 900₽/час
                            <br />
                            <br />
                            Залог:
                            <br />
                            - 10,000₽ - стандартный
                            <br />
                            - 20,000₽ - при проведении мероприятий
                            <br />
                            <br />
                            Отчетные документы командировочным специалистам по запросу
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.FooterWrapper}>
                <Footer />
            </div>
            {imageRef && isOpenGallery &&
                <Gallery device={device} handleGallery={handleGallery} imgRef={imageRef} currentImages={currentScroll} />}
        </div>
    )
}

export default WonderfulPageApart