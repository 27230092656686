import React, { useEffect, useState } from 'react'
import styles from './GalleryPage.module.scss'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../fireebase';
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet-async';

const GalleryPage = () => {
    const [galleryImages, setGalleryImages] = useState<string[]>([]);
    useEffect(() => {
        const fetchGallery = async () => {
            try {
                // Ссылка на папку с изображениями
                const folderRef = ref(storage, "Gallery");

                // Получаем список всех файлов в папке
                const result = await listAll(folderRef);

                // Получаем URL для каждого изображения
                const urlPromises = result.items.map(item => getDownloadURL(item));
                const urls = await Promise.all(urlPromises);

                if (urls) {
                    setGalleryImages(urls)
                }
            } catch (error) {
                console.error('Ошибка при получении ссылок на фотографии:', error);
                return [];
            }
        };
        fetchGallery();
    }, []);
    return (
        <div className={styles.MasonryGallery}>
            {/* <div className={styles.logo}>
                <img src='/images/new_logo.svg'></img>
            </div>
            <a href='/' className={styles.back}>
                <img src='/images/long_arrow.svg'></img>
                <span>На главную</span>
            </a> */}
            <Helmet>
                <meta name="description"
                    content="Галерея апартаментов Cozy Aparts в Москва-Сити. Комфортные и стильные квартиры с быстрым бронированием. Без скрытых платежей и обмана с залогом. Отличный выбор для краткосрочного проживания!" />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Апартаменты, Москва Сити, Снять, Посутоточно, Cozy Aparts, Аренда" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://cozyaparts.com/gallery" />
                <meta property="og:title"
                    content="Cozy Aparts – Посуточная аренда апартаментов в Москва-Сити с комфортом и без скрытых платежей" />
                <meta property="og:description"
                    content="Галерея апартаментов Cozy Aparts в Москва-Сити. Быстрое бронирование, прозрачные условия, никаких скрытых платежей и обмана с залогом!" />
                <meta property="og:image" content="https://cozyaparts.com/images/logo.jpg" />
                <link rel="apple-touch-icon" href="/images/logo.jpg" />
                <link rel="manifest" href="manifest.json" />
                <title>Cozy Aparts – Посуточная аренда апартаментов в Москва-Сити | Удобное бронирование без скрытых платежей</title>
            </Helmet>
            <div className={styles.Header}>
                <a href='/' className={styles.back}>
                    {/* <img src='/images/long_arrow.svg'></img> */}
                    <img src='/images/long_arrow_black.svg'></img>
                    <span>На главную</span>
                </a>
                <a href='/' className={styles.logo}>
                    {/* <img src='/images/new_logo.svg'></img> */}
                    <img src='/images/new_logo_black.svg'></img>
                </a>
                <a href="tel:+79299023710" className={styles.numberHeader}>+7 (929) 902 37 10</a>
            </div>
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 480: 2, 768: 3, 1200: 4 }} gutter={"10px"}
                horizontalOrder={true}
                stragger={30}
            >
                <Masonry>
                    {galleryImages.map((img, i) =>
                        <>
                            <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                        </>
                    )}
                    {galleryImages.map((img, i) =>
                        <>
                            <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                        </>
                    )}
                    {galleryImages.map((img, i) =>
                        <>
                            <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                        </>
                    )}
                </Masonry>
            </ResponsiveMasonry>
            <div className={styles.FooterWrapper}>
                <Footer />
            </div>
        </div>
    )
}

export default GalleryPage