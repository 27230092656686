import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import WonderfulAparts from './Pages/WonderfulAparts/WonderfulAparts';
import WonderfulPageApart from './Pages/WonderulPageApart/WonderfulPageApart';
import HomePageBuild from './Pages/HomePageBuild/HomePageBuild';
import GalleryPage from './Pages/GalleryPage/GalleryPage';
import { HelmetProvider } from 'react-helmet-async';

var lang = window.navigator.language.split("-")[0]
const langValue = localStorage.getItem("lang");

if (langValue) {
  lang = langValue;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const changeLang = (newLang: string) => {
  localStorage.setItem("lang", newLang);
  lang = newLang;
  window.location.reload()
}
const router = createBrowserRouter([
  {
    path: "/apartments",
    element: <WonderfulAparts />
  },
  {
    path: "/apartments/:nameApart",
    element: <WonderfulPageApart />,
  },
  {
    path: "/gallery",
    element: <GalleryPage />,
  },
  {
    path: "/",
    element: <HomePageBuild />
  },
]);

root.render(
  <HelmetProvider>
    <RouterProvider router={router}></RouterProvider>
  </HelmetProvider>,
  // <RouterProvider router={router} />
);
