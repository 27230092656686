import React, { useEffect, useRef, useState } from 'react'
import styles from './HomePageBuild.module.scss'
import WonderfulHeader from '../../Components/WonderfulHeader/WonderfulHeader'
import WonderfulAparts from '../WonderfulAparts/WonderfulAparts'
import Map from '../../Components/Map/Map'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { getDownloadURL, listAll, ref } from 'firebase/storage'
import { storage } from '../../fireebase'
import Footer from '../../Components/Footer/Footer'
import Loading from '../../Components/Loading/Loading'
import { Helmet } from 'react-helmet-async'
const navs = ["Cozy Aparts", "О нас", "Бронирование", "Галлерея", "Отзывы", "Контактная информация"];
interface IIcon {
    img: string,
    title: string,
    descr: string,
}
const benefits = [
    {
        img: "/images/icon1.svg",
        title: "Работа напрямую",
        descr: "Никаких комиссий — вы платите только за проживание.",
    },
    {
        img: "/images/icon2.svg",
        title: "На вкус и стоимость",
        descr: "Апартаменты для любых целей: от деловых встреч до семейного отдыха.",
    },
    {
        img: "/images/icon3.svg",
        title: "Всегда чисто",
        descr: "Гости единогласно отмечают чистоту апартаментов.",
    },
    {
        img: "/images/icon4.svg",
        title: "Работаем честно",
        descr: "Возвращаем залоги и не подставляем клиентов перед мероприятиями.",
    },
    {
        img: "/images/icon5.svg",
        title: "Wi-Fi и рабочее место",
        descr: "Во всех апартаментах есть Wi-Fi, а в некоторых предусмотрено комфортабельное рабочее место.",

    },
    {
        img: "/images/icon6.svg",
        title: "Уютно как дома",
        descr: "Мягкие халаты, полотенца, тапочки, гигиенические наборы, постельные принадлежности — это минимум того, что есть в каждом апартаменте.",

    },
] as IIcon[]
const HomePageBuild = () => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const currentSCrollRef = useRef(0);
    const currentPageRef = useRef(0);
    const [instantAparts, setInstantAparts] = useState(false);
    const [instantGallery, setInstantGallery] = useState(false);
    const lockScroll = useRef(false);
    const [reveiwScroll, setReveiwScroll] = useState(0)
    const [height, setHeight] = useState(window.innerHeight);
    var lastScroll = 0;
    const page1 = useRef<HTMLDivElement>(null);
    const page2 = useRef<HTMLDivElement>(null);
    const page3 = useRef<HTMLDivElement>(null);
    const page4 = useRef<HTMLDivElement>(null);
    const page5 = useRef<HTMLDivElement>(null);
    const page6 = useRef<HTMLDivElement>(null);
    const page7 = useRef<HTMLDivElement>(null);
    const mainContent = useRef<HTMLDivElement>(null);
    const pages = [page1, page2, page3, page4, page5, page6, page7];
    var scrollTimeout: ReturnType<typeof setTimeout> | null = null;
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [device, setDevice] = useState(window.innerWidth > 1200 ? "PC" : window.innerWidth > 850 ? "Laptop" : window.innerWidth > 780 ? "Tablet" : "Mobile");
    const [galleryImages, setGalleryImages] = useState<string[]>([]);
    const setPage = (e: WheelEvent) => {
        // if (scrollTimeout !== null) {
        //     clearTimeout(scrollTimeout);
        // }
        if (device !== "Mobile" && device !== "Tablet") {
            e.preventDefault()
        }
        if (!lockScroll.current) {
            if (device !== "Mobile" && device !== "Tablet") {
                document.body.style.overflow = 'hidden';
                lockScroll.current = true;
                // if (window.scrollY > currentSCrollRef.current) {
                if (e.deltaY > 10) {
                    if (currentPageRef.current < pages.length - 1) {
                        currentPageRef.current += 1;
                    } else {
                        lockScroll.current = false;
                    }
                }
                else if (e.deltaY < -10) {
                    if (currentPageRef.current > 0) {
                        console.log(currentPageRef.current);
                        currentPageRef.current -= 1;
                    } else {
                        lockScroll.current = false;
                    }
                }
                else {
                    lockScroll.current = false;
                }
                window.scrollTo(0, 0)
                setCurrentPage(currentPageRef.current)
            }
            else {
                var nextPage = (window.scrollY + height / 2);
                if (nextPage > 1.5 * height && page2.current)
                    nextPage -= page2.current?.getBoundingClientRect().height / height;
                setCurrentPage(Math.floor(nextPage / height));
            }
        }
        currentSCrollRef.current = window.scrollY;
        setCurrentScroll(window.scrollY);
    }
    const setScroll = (e: Event) => {
        if (device === "Mobile" || device === "Tablet") {
            var nextPage = (window.scrollY + height / 2);
            if (nextPage > 1.5 * height && page2.current)
                nextPage -= page2.current?.getBoundingClientRect().height / height;
            setCurrentPage(Math.floor(nextPage / height));
        }
        else {
            e.preventDefault();
        }
    }
    useEffect(() => {
        if (device !== "Mobile" && device !== "Tablet") {
            document.body.style.overflow = 'hidden';
        }
    }, [device])
    const plusReveiw = () => {
        if (reveiwScroll < 12) {
            setReveiwScroll(reveiwScroll + 1)
        }
    }
    const minusReveiw = () => {
        if (reveiwScroll > 0) {
            setReveiwScroll(reveiwScroll - 1)
        }
    }
    useEffect(() => {
        const fetchGallery = async () => {
            try {
                // Ссылка на папку с изображениями
                const folderRef = ref(storage, "Gallery");

                // Получаем список всех файлов в папке
                const result = await listAll(folderRef);

                // Получаем URL для каждого изображения
                const urlPromises = result.items.map(item => getDownloadURL(item));
                const urls = await Promise.all(urlPromises);

                if (urls) {
                    setGalleryImages(urls)
                }
            } catch (error) {
                console.error('Ошибка при получении ссылок на фотографии:', error);
                return [];
            }
        };
        fetchGallery();
        const updateHeight = () => {
            setHeight(window.innerHeight);
            if (window.innerWidth > 1200) {
                setDevice("PC")
            }
            else if (window.innerWidth > 850) {
                setDevice("Laptop")
            }
            else if (window.innerWidth > 780) {
                setDevice("Tablet")
            }
            else {
                setDevice("Mobile")
            }
            // setDevice("Mobile")
        }
        window.addEventListener("wheel", setPage, { passive: false });
        window.addEventListener("scroll", setScroll, { passive: false });
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("wheel", setPage);
            window.addEventListener("scroll", setScroll);
            window.removeEventListener('resize', updateHeight);
        };
    }, []);
    useEffect(() => {
        if (!instantAparts && currentPage === 2) {
            setTimeout(() => {
                setInstantAparts(true);
            }, 1000)
        }
        if (!instantGallery && (currentPage === 3 || currentPage === 4)) {
            setTimeout(() => {
                setInstantGallery(true);
            }, 1000)
        }
        if (device !== "Mobile" && device !== "Tablet") {
            // @ts-ignore
            // pages[currentPage].current?.scrollIntoView({ behavior: "smooth", block: "start" });
            lockScroll.current = true;
            setTimeout(() => {
                currentSCrollRef.current = window.scrollY;
                currentPageRef.current = currentPage;
                lockScroll.current = false;
            }, 1020)
        }
        console.log(currentPage);
    }, [currentPage])
    useEffect(() => {
        if (isOpenMenu) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpenMenu])
    useEffect(() => {
        if (device !== "Mobile" && device !== "Tablet") {
            window.scrollTo(0, 0);
            document.body.scrollTo(0, 0);
        }
    }, [pages])
    return (

        <div className={styles.HomePage}>
            <Helmet>
                <meta name="description"
                    content="Аренда посуточных апартаментов в Москва-Сити. Комфортные и стильные квартиры с быстрым бронированием. Без скрытых платежей и обмана с залогом. Отличный выбор для краткосрочного проживания!" />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Апартаменты, Москва Сити, Снять, Посутоточно, Cozy Aparts, Аренда" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://cozyaparts.com/" />
                <meta property="og:title"
                    content="Cozy Aparts – Посуточная аренда апартаментов в Москва-Сити с комфортом и без скрытых платежей" />
                <meta property="og:description"
                    content="Арендуйте стильные и комфортные апартаменты в Москва-Сити на сутки. Быстрое бронирование, прозрачные условия, никаких скрытых платежей и обмана с залогом!" />
                <meta property="og:image" content="https://cozyaparts.com/images/logo.jpg" />
                <link rel="apple-touch-icon" href="/images/logo.jpg" />
                <link rel="manifest" href="manifest.json" />
                <title>Cozy Aparts – Посуточная аренда апартаментов в Москва-Сити | Удобное бронирование без скрытых платежей</title>
            </Helmet>
            {(device === "Tablet" || device === "Mobile") &&
                <div className={`${styles.MobileMenu} ${isOpenMenu ? styles.open : ""}`} >
                    <div className={styles.content}>
                        {navs.map((e, i) =>
                            <a onClick={() => {
                                if (!lockScroll.current) {
                                    if (device === "Tablet" || device === "Mobile") {
                                        setIsOpenMenu(false);
                                        var page = i;
                                        if (page < 4) {

                                        }
                                        else {
                                            page++;
                                        }
                                        console.log(page);
                                        pages[page].current?.scrollIntoView({ behavior: "smooth", block: "start" })
                                    }
                                }
                            }} className={`${styles.link} ${i === currentPage ? styles.active : ""}`}>
                                <button>{e}</button>
                            </a>
                        )}
                    </div>
                    {/* <img className={styles.logo} src='/images/new_logo.svg'></img> */}
                    <div className={styles.exit} onClick={() => setIsOpenMenu(false)}></div>
                </div>
            }
            <div className={`${styles.mobileHeader} ${(device === "Mobile" || device === "Tablet") && currentPage > 0 ? styles.visible : ""}`}>
                <img className={styles.logo} src='/images/new_logo2.svg'></img>
                <a href="tel:+79299023710" className={styles.numberHeader}>+7 (929) 902 37-10</a>
                <div className={styles.buttonMenu} onClick={() => setIsOpenMenu(true)}>

                </div>
            </div>
            <div className={`${styles.nav}`}>
                {navs.map((e, i) =>
                    <button onClick={() => {
                        if (!lockScroll.current) {
                            if (device === "Tablet" || device === "Mobile") {
                                pages[i].current?.scrollIntoView({ behavior: "smooth", block: "start" })
                                setCurrentPage(i);
                            }
                            else {
                                document.body.style.overflow = 'hidden';
                                lockScroll.current = true;
                                var page = i;
                                if (page < 4) {

                                }
                                else {
                                    page++;
                                }
                                setCurrentPage(page);
                            }
                        }
                    }} className={`${styles.link} ${(e === "Галлерея" && currentPage > 2 && currentPage < pages.length - 2) || (e === "Отзывы" && currentPage === pages.length - 2) || (e === "Контактная информация" && currentPage === pages.length - 1) || (e !== "Отзывы" && e !== "Галлерея" && e !== "Контактная информация" && i === currentPage) ? styles.active : ""}`}>
                        <span>{e}</span>
                    </button>

                )}
            </div>
            {/* <div className={styles.headerWrapper}>
                <WonderfulHeader color='white' />
                </div> */}
            <div className={styles.mainContent} style={{
                //@ts-ignore
                transform: `translateY(${(device !== "Mobile" && device !== "Tablet") ? ((-currentPage * height) + (currentPage === 6 ? (height - 270) : 0)) : 0}px)`
            }} ref={mainContent}>

                <div ref={page1} className={`${styles.firstPage} ${((device === "Tablet" || device === "Mobile") && currentPage >= 0) || ((device !== "Tablet" && device !== "Mobile") && currentPage == 0) ? styles.visible : ""}`} style={{ height: device !== "Mobile" && device !== "Tablet" ? height : "100vh" }}>
                    <div className={styles.buttonMenuPage} onClick={() => setIsOpenMenu(true)}></div>
                    <a href="tel:+79299023710" className={styles.number} style={{
                        transform: device !== "Mobile" && device !== "Tablet" ? `translateY(${(currentPage * height - (currentPage === 6 ? height - 270 : 0)) + "px"})` : ""
                    }}>
                        +7 (929) 902 37-10
                    </a>
                    <div className={styles.imageWrapper}>
                        {device !== "Mobile" ? <img src='/images/mainImage_pc.webp'></img> :
                            <img src='/images/e.webp'></img>
                        }
                    </div>
                    <div className={`${styles.logo} ${styles["phaze" + currentPage]}`}><img src={`/images/new_logo.svg`} style={{
                        transform: device !== "Mobile" && device !== "Tablet" ? `translateX(-50%) translateY(${(currentPage * height) + (currentPage === 0 ? 0 : currentPage === 1 ? -90 : currentPage === 6 ? -1000 : -300) + "px"}) scale(${currentPage === 0 ? "1" : "0.6"})` : ""
                    }} /></div>
                    <div className={styles.textBlock}>
                        <h1 className={`${styles.big} ${styles.bold}`} style={{
                            marginBottom: "10px"
                        }}>Апартаменты на сутки Москва Сити</h1>
                        <h2 className={`${styles.thin}`}>Комфортный отдых в центре мегаполиса</h2>
                        {device === "Tablet" || device === "Mobile"
                            &&
                            <button onClick={() => {
                                console.log(lockScroll.current)
                                if (!lockScroll.current) {
                                    setCurrentPage(2);
                                    page3.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                                }
                            }} className={`${styles.buttonBook} ${styles.glassButton}`}>К выбору апартаментов</button>
                        }
                    </div>
                    <div className={styles.explore}>
                        <img src='/images/long_arrow.svg' />
                        <button onClick={() => {
                            if (!lockScroll.current)
                                if (device === "Tablet" || device === "Mobile") {
                                    page2.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                                    setCurrentPage(2);
                                }
                                else {
                                    document.body.style.overflow = 'hidden';
                                    lockScroll.current = true;
                                    setCurrentPage(1);
                                }
                        }} className={`${styles.buttonExplore} ${styles.glassButton}`}>Узнать больше</button>
                    </div>
                    {device === "Laptop" || device === "PC"
                        &&
                        <div onClick={() => {
                            if (!lockScroll.current) {
                                lockScroll.current = true;
                                setCurrentPage(2);
                            }
                        }} className={`${styles.buttonBook} ${styles.glassButton}`}>К выбору апартаментов</div>
                    }
                </div>
                <div className={`${styles.secondPage} ${((device === "Tablet" || device === "Mobile") && currentPage >= 1) || ((device !== "Tablet" && device !== "Mobile") && currentPage == 1) ? styles.visible : ""}`} style={{ height: device !== "Mobile" && device !== "Tablet" ? height : "auto" }} ref={page2}>
                    <div className={styles.leftSide} />
                    {
                        device !== "Mobile" &&
                        <div className={styles.imageWrapper}>
                            <img src='/images/benefits.webp'></img>
                        </div>
                    }
                    <div className={styles.textBlock}>
                        <span className={`${styles.big} ${styles.bold}`}>Дом вдалеке от дома</span>
                        <span className={`${styles.thin}`}>Наши апартаменты идеально подходят для бизнес-встреч, вечеринок, дней рождений, свиданий и романтических поездок, а также для отдыха всей семьёй.</span>

                        <div className={styles.benefitsWrapper}>
                            <div className={styles.benefits}>
                                {
                                    (device === "Mobile") &&
                                    <div className={styles.imageWrapper}>
                                        <img src='/images/benefits.webp'></img>
                                    </div>
                                }
                                <div className={`${styles.left} ${styles.benefitsBlock}`}>
                                    {benefits.slice(0, 3).map((b, i) =>
                                        <div className={styles.benefit} >
                                            <img src={b.img}></img>
                                            <div className={styles.textBenefit}>
                                                <span className={styles.title}>{b.title}</span>
                                                <span className={styles.descr}>{b.descr}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={`${styles.right} ${styles.benefitsBlock}`}>
                                    {benefits.slice(-3).map((b, i) =>
                                        <div className={styles.benefit}  >
                                            <img src={b.img}></img>
                                            <div className={styles.textBenefit}>
                                                <span className={styles.title}>{b.title}</span>
                                                <span className={styles.descr}>{b.descr}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.thirdPage} ${(((device === "Tablet" || device === "Mobile") && currentPage >= 2) || ((device !== "Tablet" && device !== "Mobile") && currentPage === 2)) && instantAparts ? styles.visible : ""}`} style={{ height: device !== "Mobile" && device !== "Tablet" ? height : "100vh" }} ref={page3}>
                    <div className={`${styles.loading} ${!instantAparts && currentPage === 2 ? styles.visible : ""}`}>
                        <span>Загрузка апартаметов</span>
                        <Loading />
                    </div>
                    <div className={styles.apartsWrapper}>
                        <WonderfulAparts />
                    </div>
                </div>
                <div className={`${styles.fourthPage} ${(((device === "Tablet" || device === "Mobile") && currentPage >= 3) || ((device !== "Tablet" && device !== "Mobile") && (currentPage === 3 || currentPage === 4))) && instantGallery ? styles.visible : ""}`} style={{ height: (device !== "Tablet" && device !== "Mobile") ? height * 2 : "200vh" }} ref={page4}>
                    <div className={`${styles.loading} ${!instantGallery && (currentPage === 3 || currentPage === 4) ? styles.visible : ""}`}
                        style={{
                            top: currentPage === 3 ? "50vh" : currentPage === 4 ? "150vh" : "",
                        }}>
                        <span>Загрузка галереи</span>
                        <Loading />
                    </div>
                    <div className={styles.MasonryGallery}>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 480: 2, 768: 3, 1200: 4 }} gutter={"10px"}
                            horizontalOrder={true}
                            stragger={30}
                        >
                            <Masonry>
                                {galleryImages.map((img, i) =>
                                    <>
                                        <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                                    </>
                                )}
                                {galleryImages.map((img, i) =>
                                    <>
                                        <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                                    </>
                                )}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                    <a href='/gallery' className={styles.openGallery}>
                        <span className={`${styles.big} ${styles.bold}`}>посмотреть все фотографии
                            <img src='/images/long_arrow.svg'></img></span>
                    </a>
                </div>
                <div className={`${styles.pageReveiew} ${((device === "Tablet" || device === "Mobile") && currentPage >= 5) || ((device !== "Tablet" && device !== "Mobile") && (currentPage == 5 || currentPage === 6)) ? styles.visible : ""} ${currentPage === 6 ? styles.hide : ""}`} style={{ height: device !== "Mobile" && device !== "Tablet" ? height : "100vh" }} ref={page6}>
                    <span className={`${styles.big} ${styles.bold}`}>Что о нас пишут гости</span>
                    <div className={styles.reveiwsOuter}>
                        <div className={styles.reveiwsWrapper}>
                            <div className={styles.reveiws} style={{
                                width: 700 * 13 + "px",
                                transform: `translateX(${-700 * reveiwScroll}px)`
                            }}>
                                {Array.from({ length: 13 }, (_, index) => index).map((i) =>
                                    <div className={styles.reveiw}>
                                        <img src={`/images/reveiews/a (${(i + 1) * 2 - 1}).jpg`}></img>
                                        <img src={`/images/reveiews/a (${(i + 1) * 2}).jpg`}></img>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button onClick={minusReveiw} className={`${reveiwScroll > 0 ? styles.visible : ""} ${styles.buttonReveiwLeft} ${styles.buttonReveiw}`}></button>
                        <button onClick={plusReveiw} className={`${reveiwScroll < 12 ? styles.visible : ""} ${styles.buttonReveiwRight} ${styles.buttonReveiw}`}></button>
                    </div>
                </div>
                <div className={`${styles.pageEnd} ${((device === "Tablet" || device === "Mobile") && currentPage >= 5) || ((device !== "Tablet" && device !== "Mobile") && currentPage == 6) ? styles.visible : ""}`} style={{ height: device !== "Mobile" && device !== "Tablet" ? "270px" : "500px" }} ref={page7}>
                    <Footer color="white" />
                </div>
            </div>
        </div >
    )
}

export default HomePageBuild